import React, { useEffect, useState, useRef } from "react";
import "./RubricComponentStyle.css";
const Rubric = ({
	source,
	objectFit,
	zIndex,
	id,
	rubricNewsIntroLifetime,
	rubricDetails,
	audio,
}) => {
	const [image, setImage] = useState(rubricDetails[0].imageDetails[0]);
	const [newsContent, setNewsContent] = useState("");
	const [newsContentIndex, setNewsContentIndex] = useState(0);
	const [newsTitle, setNewsTitle] = useState(rubricDetails[0].newsTitle);

	let style = {
		height: "100%",
		width: "100%",
		backgroundColor: "#ffffff",
		objectFit: objectFit,
		overflow: "hidden",
		zIndex: zIndex,
	};

	const createMarkup = (string) => {
		if (string !== "") {
			return { __html: string };
		}
	};

	useEffect(() => {
		let isMounted;
		if (newsContentIndex === 0) {
			isMounted = setTimeout(() => {
				if (document.getElementById(id)) {
					document.getElementById(id).style.display = "none";
					document.getElementById(`shadow${id}`).style.display = "flex";
					document.getElementById(`widgetImage${id}`).style.display =
						"inline-block";
				}
			}, rubricNewsIntroLifetime * 1000);
		}
		return () => clearTimeout(isMounted);
	}, []);

	useEffect(() => {
		let isMounted;
		let newsInterval;
		if (newsContentIndex < rubricDetails.length) {
			let imageIndex = 0;
			let outputIndex = 0;
			if (document.getElementById("widgetContentP").style.display === "none") {
				setTimeout(() => {
					document.getElementById("widgetContentP").style.display = "block";
				}, rubricDetails[newsContentIndex].outputLifetime * 1000);
			}
			newsInterval = setInterval(() => {
				document.getElementById(`newsTitle${id}`) &&
					(document.getElementById(`newsTitle${id}`).style.display = "none");
				document.getElementById(`widgetContent${id}`) &&
					(document.getElementById(`widgetContent${id}`).style.display =
						"flex");
				if (imageIndex < rubricDetails[newsContentIndex].imageDetails.length) {
					setImage(rubricDetails[newsContentIndex].imageDetails[imageIndex]);
					imageIndex++;
				}
				if (outputIndex < rubricDetails[newsContentIndex].output.length) {
					setNewsContent(rubricDetails[newsContentIndex].output[outputIndex]);
					outputIndex++;
				}
			}, rubricDetails[newsContentIndex].outputLifetime * 1000);
			if (
				newsContentIndex + 1 < rubricDetails.length &&
				rubricDetails.length > 1
			) {
				isMounted = setTimeout(() => {
					document.getElementById(`newsTitle${id}`).style.display = "block";
					document.getElementById("widgetContentP").style.display = "none";
					if (newsContentIndex + 1 < rubricDetails.length) {
						setNewsContentIndex((prevState) => prevState + 1);
						setNewsTitle(rubricDetails[newsContentIndex + 1].newsTitle);
						setImage(rubricDetails[newsContentIndex + 1].imageDetails[0]);
					}
					clearInterval(newsInterval);
				}, rubricDetails[newsContentIndex].newsLifetime * 1000);
			}
		}
		return () => {
			clearTimeout(isMounted);
			clearInterval(newsInterval);
		};
	}, [newsContentIndex]);

	useEffect(() => {
		if (zIndex === 0 && document.getElementById(`audio${id}`).src) {
			document.getElementById(`audio${id}`).src = "";
		}
	}, [zIndex]);

	return (
		<div style={style}>
			<audio autoPlay loop id={`audio${id}`}>
				<source src={audio} type="audio/mp3" />
			</audio>
			<video id={id} autoPlay muted style={style}>
				<source src={source} type="video/mp4" />
			</video>
			<div id={`widgetImage${id}`}>
				<React.Fragment key={image.src}>
					<img src={image.src} style={style} alt="background-image" />
				</React.Fragment>
				<div
					id={`shadow${id}`}
					style={{ height: rubricDetails[0].paragraphHeight + "%" }}
				>
					<h1 id={`newsTitle${id}`}>{newsTitle}</h1>
					<div id={`widgetContent${id}`}>
						<React.Fragment key={newsContent}>
							<p
								id="widgetContentP"
								dangerouslySetInnerHTML={createMarkup(newsContent)}
								style={{ fontSize: rubricDetails[0].fontSize }}
							></p>
						</React.Fragment>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Rubric;
