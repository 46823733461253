export const mediaServer = () => {
	let urlString = window.location.href;
	let url = new URL(urlString);
	let mediaServer = url.searchParams.get("mediaServerUrl");
	return mediaServer ? mediaServer : "http://localhost:81/api/";
	// return "http://10.0.32.158:81/api/";
};

export const mediaServerWS = () => {
	let urlString = window.location.href;
	let url = new URL(urlString);
	let mediaServerWS = url.searchParams.get("mediaServerWSURL");
	return mediaServerWS ? mediaServerWS : "ws://10.0.32.158:18085";
};

export const apiConfig = {
	API_URL: mediaServer(),
	PATHS: {
		IDENTIFY: "identify/?getjson=1",
		HELLO: (playerID) => {
			return `hello/${playerID}/?getjson=1`;
		},
		CONTENT: (widgetID) => {
			return `content/${widgetID}/?getjson=1`;
		},
		RECORD: (widgetID) => {
			return `record/${widgetID}/?getjson=1&`;
		},
	},
};
