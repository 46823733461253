import React, { useEffect } from "react";

const Iframe = ({ source, objectFit, zIndex, id, audio }) => {
	let style = {
		height: "100%",
		width: "100%",
		backgroundColor: "#ffffff",
		objectFit: objectFit,
		objectPosition: "center",
		zIndex: zIndex,
	};

	useEffect(() => {
		if (zIndex === 0 && document.getElementById(`audio${id}`).src) {
			document.getElementById(`audio${id}`).src = "";
		}
	}, [zIndex]);
	return (
		<React.Fragment key={id}>
			<audio autoPlay loop id={`audio${id}`}>
				<source src={audio} type="audio/mp3" />
			</audio>
			<iframe frameBorder="0" src={source} title={id} style={style}></iframe>
		</React.Fragment>
	);
};

export default Iframe;
