import getWidgetDetails from "../../../helpers/getWidgetDetailsApi";
import { GET_WIDGETS_SUCCESS } from "../constants";

const getWidgets = () => async (dispatch, getState) => {
  try {
    const playerID = await getWidgetDetails.getPlayerID();
    if (playerID !== undefined) {
      const widgetID = await getWidgetDetails.getWidgetID(playerID);
      dispatch(getWidgetsSucces(playerID, widgetID));
    }
  } catch {
    const intervalFallback = setInterval(async () => {
      const playerID = await getWidgetDetails.getPlayerID();
      if (playerID !== undefined) {
        clearInterval(intervalFallback);
        const widgetID = await getWidgetDetails.getWidgetID(playerID);
        dispatch(getWidgetsSucces(playerID, widgetID));
      }
    }, 10000);
  }
};

const getWidgetsSucces = (playerID, widgets) => {
  return {
    type: GET_WIDGETS_SUCCESS,
    playerID,
    payload: widgets,
  };
};

export default getWidgets;
