import * as events from "./actionsNames";

const webSocketEvents = (data) => {
	return {
		type: events.PLAY_NOW,
		payload: { ...data },
	};
};

export default webSocketEvents;
