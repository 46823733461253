import React, { useRef, useEffect } from "react";
import "./NewsbarComponentStyle.css";
import { TweenMax, Power3 } from "gsap";

const Newsbar = ({
	title,
	zIndex,
	content,
	contentKeys,
	transitionTimer,
	id,
	audio,
}) => {
	let slideLeft = useRef("null");
	let slideUp = useRef("null");
	let root = document.documentElement;
	useEffect(() => {
		TweenMax.to(slideUp, 0.5, {
			top: 0,
			ease: Power3.linear,
		});

		TweenMax.to(slideLeft, 0.5, {
			top: "0",
			ease: Power3.linear,
		});
		root.style.setProperty(
			"--newsbarTransition-duration",
			transitionTimer + "s"
		);
	}, []);

	useEffect(() => {
		if (zIndex === 0 && document.getElementById(`audio${id}`).src) {
			document.getElementById(`audio${id}`).src = "";
		}
	}, [zIndex]);

	const style = {
		height: "100%",
		width: "100%",
		display: "flex",
		backgroundColor: "#ffffff",
		zIndex: zIndex,
		overflow: "hidden",
	};

	const createMarkup = (string) => {
		return { __html: string };
	};

	return (
		<React.Fragment key={transitionTimer}>
			<div style={style}>
				<audio autoPlay loop id={`audio${id}`}>
					<source src={audio} type="audio/mp3" />
				</audio>
				<div className="source-titleBackground">
					<div
						className="source-title"
						ref={(item) => {
							slideUp = item;
						}}
					>
						{title}
					</div>
				</div>
				<div
					className="source-content source-contentBackground"
					ref={(item) => {
						slideLeft = item;
					}}
				>
					{content.map((el, index) => {
						return (
							<div key={contentKeys[index]}>
								<span dangerouslySetInnerHTML={createMarkup(el)}></span>
								<span className="separator">&#9632;</span>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Newsbar;
