const initialState = {
	loading: true,
	widgets: {},
};

const widgetReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_WIDGETS_SUCCESS":
			return {
				...state,
				loading: false,
				playerID: action.playerID,
				widgets: action.payload,
			};
		case "GET_WIDGETS_ERROR":
			return {
				loading: false,
				data: action.payload,
				error: "Something went wrong",
			};
		default:
			return state;
	}
};

export default widgetReducer;
