import * as events from "../actions/actionsNames";

const initialState = {
	playNowData: {},
};

const webSocketReducer = (state = initialState, action) => {
	switch (action.type) {
		case events.PLAY_NOW:
			return {
				...state,
				playNowData: {
					...action.payload,
				},
			};
		default:
			return {
				...state,
			};
	}
};

export default webSocketReducer;
